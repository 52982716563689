<template>
    <article>
        <div class="bar">
            <template v-if="item.tag === 'tickets_created'">
                <span v-for="(bar, i) in Object.keys(values)" :key="i" :title="`${values[bar]} tickets of ${current} were created by ${bar}`" :style="{width: `${values[bar] ? ((values[bar] / current)  * 100) : 0}%`, background:item.color, opacity: i ? 0.5 : 0.7}" />
            </template>
            <span v-else :title="`${values} tickets of ${current}`" :style="{width: `${values ? (values / current  * 100) : 0}%`, background: item.color, opacity: 0.7}" />
        </div>
        <div v-if="item.tag === 'tickets_created'" :style="{color:item.color}">
            <span><small>created by Organisation:</small> <strong>{{values?.org || 0}}</strong></span>
            <span style="opacity: 0.7"><small>created by Clients:</small> <strong>{{values?.client || 0}}</strong></span>
        </div>
        <span class="item" :style="{color:item.color}" v-else><strong>{{values || 0}}</strong> <small>{{item.name}}</small></span>
    </article>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        values: {
            type: String || Object,
            default: () => ''
        },
        current: {
            type: Number,
            default: () => 0
        }
    }
}
</script>

<style lang="scss" scoped>
    article {
        width: 100%;
        position: relative;
        overflow: hidden;
        color: black;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        .bar {
            width: 60%;
            height: 40px;
            border-radius: 2px;
            background: rgba(0,0,0,0.1);
            position: relative;
            display: flex;
            flex-direction: row;
            transition: 1s ease-in-out;
            @media only screen and (max-width: 768px) {
                width: 52%;
            }

            span {
                height: 100%;
                width: 0%;
                transition: 0.5s ease-in-out;

                &:hover {
                    opacity: 1 !important;
                }
            }
        }

        small {
            font-size: 12px;
            @media only screen and (max-width: 768px) {
                font-size: 10px;
            }
        }

        div {
            display: flex;
            flex-direction: column;
        }

        

        .item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
            font-size: 24px;
        }
    }
</style>