<template>
    <section>
        <div class="coming-soon">
            <span>Coming Soon</span>
        </div>
        <header>
            <h2>{{title}}</h2>
        </header>
        <div class="container">
            <div class="text">
                <div class="small">In GB</div>
                <span>{{value}}/{{capacity}}</span>
            </div>
            <div class="ring">
                <span class="percent">{{percent}} %</span>
                <svg class="progress-ring">
                    <circle ref="circle" class="progress-ring__circle"  stroke-width="8" stroke-dasharray="220" stroke-dashoffset="220" fill="transparent" r="35" cx="40" cy="40"/>
                </svg>
            </div> 
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: () => ''
        },
        action: {
            type: String,
            default: () => ''
        },
        refresh: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            value: 0,
            used: 37,
            capacity: 120,
            percent: 0
        }
    },

    watch: {
        used() {
            this.setCounter()
        }
    },


    mounted() {
        this.setCounter()
        setTimeout(() => {
            this.setUsedCircle()
        }, 200);
        
    },

    methods: {
        setUsedCircle() {
            const circle = this.$refs.circle;
            var radius = circle.r.baseVal.value;
            var circumference = radius * 2 * Math.PI;

            circle.style.strokeDasharray = `${circumference} ${circumference}`;
            circle.style.strokeDashoffset = circumference;

            const offset = circumference - this.used / this.capacity * circumference;
            circle.style.strokeDashoffset = offset;
        },

        setCounter() {
            const duration = this.value > this.used ? 500 / this.value : 500 / this.used
            
            const counter = setInterval(() => {
                if (this.value > this.used) {
                    this.value -= 1
                    return
                }

                if (this.value < this.used) {
                    this.value += 1
                    return
                }

                clearInterval(counter)
            }, duration);

            const value = Math.round(this.used / this.capacity * 100)
            const percent_duration = this.percent > value ? 500 / this.percent : 500 / value

            const percent = setInterval(() => {
                if (this.percent > value) {
                    this.percent -= 1
                    return
                }

                if (this.percent < value) {
                    this.percent += 1
                    return
                }

                clearInterval(percent)
            }, percent_duration);
        }
    },
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    // width: 20rem;
    flex-direction: column;
    padding: 20px;
    background: rgba(140, 140, 140, 0.1);
    border-radius: 2px;
    justify-content: space-between;
    position: relative;
    user-select: none;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;

    @media only screen and (max-width: 1200px) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    @media only screen and (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 8;
        grid-row-end: 9;
    }

    .coming-soon {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(140, 140, 140, 0.2);
        backdrop-filter: blur(3px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        color: white;
        z-index: 2;
    }

    header {
        display: flex;
        align-items: center;
        width: 100%;

        h2 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
            user-select: none;
        }
    }

    .container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 1rem;
        
        span {
            color: var(--primary-color);
            font-size: 40px;
            font-weight: 600;
        }

        .text {
            .small {
                font-size: 12px;
            }
        }

        .ring {
            background: white;
            border-radius: 50%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;

            .percent {
                position: absolute;
                font-size: 12px;
            }

            &::after {
                content: '';
                position: absolute;
                width: 60px;
                height: 60px;
                background: rgba(140, 140, 140, 0.1);
                border-radius: 50%;
            }

            .progress-ring {
                width: 100%;
                height: 100%;
                position: absolute;
            }

            .progress-ring__circle {
                position: absolute;
                stroke: var(--primary-color);
                transition: 1s stroke-dashoffset ease-in-out;
                transform: rotate(-90deg);
                transform-origin: 50% 50%;
            }
        }

        

        
    }
}
</style>