<template>
    <div class="filter">
        <div class="selected" :class="{active: selected}" @click="showOptions = !showOptions">
            <span>{{selected ? options.find(el => el.id === selected).name : data.name }}</span>
            <span class="arrow" :class="{rotate: showOptions}" />
        </div>
        <div class="options" v-if="showOptions">
            <span class="remove" v-if="selected" @click="selectItem(0)">Remove Selected</span>
            <span v-if="!filteredOptions.length">No data for this {{data.dependent}}</span>
            <template v-for="(item, i) in filteredOptions.filter(el => el.id !== selected)" :key="i">
                <span @click="selectItem(item.id)">{{item.name}}</span>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        savedFilters: {
            type: Object,
            default: () => {}
        }
    },

    data() {
        return {
            options: [],
            filteredOptions: [],
            showOptions: false,
            selected: 0
        }
    },

    watch: {
        savedFilters: {
            deep: true,
            handler() {
                this.checkDependency()
            }
        },

        selected(val) {
            this.$emit('selectItem', [this.data.name.toLowerCase(), val])
        },
    },

    async created() {
        this.options = await this.data.data
        this.checkDependency()
        
    },

    methods: {
        selectItem(id) {
            this.selected = id
            this.showOptions = false
        },

        checkDependency() {
            if (this.data.dependent && this.savedFilters[this.data.dependent]) {
                const dependency = this.savedFilters[this.data.dependent]
                this.filteredOptions = this.options.filter(el => el[this.data.dependent] === dependency)
                if (!this.filteredOptions.map(el => el.id).includes(this.selected)) this.selected = 0
                return
            }

            this.filteredOptions = this.options
        },
    },
}
</script>

<style lang="scss" scoped>
    .filter {
        background: rgba(0,0,0,0.1);
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
        position: relative;
        display: flex;
        flex-direction: column;

        .selected {
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 10px;
            transition: 0.2s;
            height: 100%;

            &.active {
                background: var(--primary-color);
                color: white;

                .arrow {
                    border-color: transparent white white transparent;
                }
            }

            .arrow {
                width: 8px;
                height: 8px;
                border: solid 1px;
                border-color: transparent black black transparent;
                transform: rotate(45deg) translate(-2px, -2px);
                transition: 0.2s;

                &.rotate {
                    transform: rotate(225deg) translate(-4px, -2px);
                }
            }
        }

        .options {
            background: white;
            position: absolute;
            left: 0;
            top: 37px;
            width: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            max-height: 200px;
            overflow: auto;

            .remove {
                background: rgba(0,0,0,0.1);

                &:hover {
                    background: $red;
                    color: white;
                }
            }

            span {
                padding: 10px;
                background: transparent;
                transition: 0.2s;
                &:hover {
                    background: rgba(0,0,0,0.1);
                }
            }
        }
    }
</style>