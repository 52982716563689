<template>
    <section>
        <header>
            <h2>{{title}}</h2>
            <aside>
                <template v-for="(filter, i) in filters" :key="i">
                    <span :class="{active: selectedGroup === i}" @click="selectedGroup = i">{{selectedGroup === i ? filter.extent : filter.short}}</span>
                </template>
            </aside>
        </header>
        <div class="container">
            <div class="details">
                <template v-for="(item,i) in actions" :key="i">
                    <article>
                        <div class="definition">
                            <span class="color" :style="{background: item.color}" />
                            <span>{{item.name}}</span>
                        </div>
                        <span class="value">{{values[i]}}</span>
                    </article>
                </template>
            </div>
            <Graph :selected-filter="selectedGroup"/>
        </div>
    </section>
</template>


<script>
import { mapActions } from "vuex";
import Graph from "@/components/Ui/Analytics/Graph/Index.vue";

export default {
    props: {
        title: {
            type: String,
            default: () => ''
        },
        actions: {
            type: Array,
            default: () => []
        },
        refresh: {
            type: Boolean,
            default: () => false
        }
    },

    components: {
        Graph
    },

    data() {
        return {
            filters: [
                {
                    short: 'W',
                    extent: 'Week'
                },
                {
                    short: 'M',
                    extent: 'Month'
                },
                {
                    short: 'Y',
                    extent: 'Year'
                }
            ],
            selectedGroup: 0,
            filteredRequest: {},
            day: new Date().getDate(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            week: undefined,
            values: [],
            finalValues: []
        }
    },

    watch: {
        finalValues(val) {
            this.$emit('newValues', val)
        },
        selectedGroup(val) {
            this.setFilters()
            this.$emit('changeView', val)
        },
        refresh(val) {
            if (val) this.getBaseAnalytics()
        },
    },

    created() {
        this.currentWeek()
        this.setFilters()
    },

    methods: {
        ...mapActions({
            getAnalytics: 'analytics/getAnalytics',
        }),

        currentWeek() {
            const currentDate = new Date(this.year, this.month, this.day);
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            this.week = Math.ceil(days / 7)

            this.actions.forEach(() => this.values.push(0))
        },

        setFilters() {
            switch (this.selectedGroup) {
                case 0:
                        this.filteredRequest = {
                            extras: [['week', this.week],['year', this.year]],
                            group: ['week']
                        }
                    break;
                case 1:
                        this.filteredRequest = {
                            extras: [['month', this.month],['year', this.year]],
                            group: ['month']
                        }
                    break;
                case 2:
                        this.filteredRequest = {
                            extras: [['year', this.year]],
                            group: ['year']
                        }
                    break;
            }

            this.getBaseAnalytics()
        },

        async getBaseAnalytics() {
            const arr = []
            this.actions.forEach(el => {
                arr.push(this.getAnalytics({action: el.action, extras: this.filteredRequest.extras, group: this.filteredRequest.group}))
            })

            const response = await Promise.all(arr)
            this.finalValues = []
            response.forEach((el, i) => {
                const value = el.data.data.data?.length ? el.data.data.data[0].count : 0
                this.finalValues.push(value)

                const duration = this.values[i] > value ? 500 / this.values[i] : 500 / value

                const counter = setInterval(() => {
                    if (this.values[i] > value) {
                        this.values[i] -= 1
                        return
                    }

                    if (this.values[i] < value) {
                        this.values[i] += 1
                        return
                    }

                    clearInterval(counter)
                }, duration);

            })
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
    // width: calc(100% - 63rem);
    background: rgba(140, 140, 140, 0.1);
    border-radius: 2px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 5;

    @media only screen and (max-width: 1200px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 4;
    }

    @media only screen and (max-width: 768px) {
        grid-row-start: 1;
        grid-row-end: 4;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;

        h2 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
        }

        aside {
            // margin-left: 2rem;
            display: flex;
            gap: 0.2rem;
            span {
                cursor: pointer;
                user-select: none;
                border-radius: 2px;
                padding: 5px;
                transition: 0.2s;

                &.active {
                    background: rgba(0,0,0,0.1);
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .details {
            display: flex;
            gap: 3rem;

            .definition {
                display: flex;
                align-items: center;
                gap: 0.2rem;
                .color {
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                }
            }
            
            .value {
                color: var(--primary-color);
                font-size: 40px;
                font-weight: 600;
            }
        }
    }
}
</style>