<template>
    <section>
        <header>
            <h2>{{title}}</h2>
        </header>
        <div class="container">
            <article>
                <div class="definition">
                    <span>Agents</span>
                </div>
                <span class="value">{{values.agents}}</span>
            </article>
            <article>
                <div class="definition">
                    <span>Users</span>
                </div>
                <span class="value">{{values.users}}</span>
            </article>
        </div>
    </section>
</template>


<script>
import axiosInstance from '@/services/AxiosTokenInstance';

export default {
    props: {
        title: {
            type: String,
            default: () => ''
        },
        action: {
            type: String,
            default: () => ''
        },
        refresh: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            values: {
                agents: 0,
                users: 0
            },
            org: localStorage.organization ? JSON.parse(localStorage.organization) : {},
        }
    },

    watch: {
        refresh(val) {
            if (val) this.getData()
        },
    },

    created() {
        this.getData()
    },

    methods: {
        async getData() {
            const response = await axiosInstance.get(`users-agents?org_id=${this.org.id}`);
            const data = response.data.data
            Object.keys(data).forEach(el => {
                const value = data[el]

                const duration = this.values[el] > value ? 500 / this.values[el] : 500 / value

                const counter = setInterval(() => {
                    if (this.values[el] > value) {
                        this.values[el] -= 1
                        return
                    }

                    if (this.values[el] < value) {
                        this.values[el] += 1
                        return
                    }

                    clearInterval(counter)
                }, duration);
            })
        },
    },
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    // width: 20rem;
    background: rgba(140, 140, 140, 0.1);
    border-radius: 2px;
    justify-content: space-between;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;

    @media only screen and (max-width: 1200px) {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    @media only screen and (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 7;
        grid-row-end: 8;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;

        h2 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
        }
    }

    .container {
        display: flex;
        gap: 3rem;

        .definition {
            display: flex;
            align-items: center;
            gap: 0.2rem;
            .color {
                width: 12px;
                height: 12px;
                border-radius: 2px;
            }
        }
        
        .value {
            color: var(--primary-color);
            font-size: 40px;
            font-weight: 600;
        }
    }
}
</style>