<template>
    <div class="global-chart">
        <div class="data">
            <div class="chart-canvas">
                <canvas ref="mainChart" width="700" height="350" />
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { mapActions } from "vuex";

export default {
    props: {
        selectedFilter: {
            type: Number,
            default: () => 0
        }
    },

    data() {
        return {
            chart: undefined,
            labels: [],
            values: [],
            selectedGroup: 0,
            filteredRequest: {},
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            week: undefined,        
        }
    },

    computed: {
        actions() {
            return [
                {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    name: 'Tickets Created',
                    tag: 'tickets_created',
                    action: 'create-ticket'
                },
                {
                    color: '#fb9a4b',
                    name: 'Ticket Calls Made',
                    tag: 'calls_made',
                    action: 'create-ticket-call'
                },
                {
                    color: '#dc3545',
                    name: 'Tickets Closed',
                    tag: 'tickets_closed',
                    action: 'close-ticket'
                }
            ]
        }
    },

    watch: {
        selectedGroup() {
            this.setLabels()
            this.setFilters()
        },

        selectedFilter(val) {
            this.selectedGroup = val
        }
    },

    mounted() {
        this.week = this.currentWeek(new Date().getDate())
        this.setLabels()
        this.setFilters()
    },

    methods: {
        ...mapActions({
            getAnalytics: 'analytics/getAnalytics',
        }),

        currentWeek(day) {
            const currentDate = new Date(this.year, this.month, day);
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            return Math.ceil(days / 7)
        },

        getWeeksInMonth(year, month) {
            const weeks = [],
                firstDate = new Date(year, month, 1),
                lastDate = new Date(year, month + 1, 0),
                numDays = lastDate.getDate();

            let dayOfWeekCounter = firstDate.getDay();

            for (let date = 1; date <= numDays; date++) {
                if (dayOfWeekCounter === 0 || weeks.length === 0) {
                weeks.push([]);
                }
                weeks[weeks.length - 1].push(date);
                dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
            }

            return weeks
                .filter((w) => !!w.length)
                .map((w) => ({
                start: w[0],
                end: w[w.length - 1],
                dates: w,
                }));
        },

        daysOfCurrentWeek() {
            const today = new Date().getDate()
            const weekDay = this.week ? today - ((this.currentWeek(new Date().getDate()) - this.week) * 7) : today

            const curr = new Date;
            const day = weekDay - curr.getDay()
            const prevMonth = this.month - 1
            const prevMonthDays = new Date(this.year, prevMonth, 0).getDate();

            const first = day > 0 ? day : prevMonthDays - (-day);

            let days = []
            let counter = 0

            for (let i = 0; i < 7; i++) {
                if ((first + i) > prevMonthDays) {
                    days.push(1+counter)
                    counter += 1
                }
                else days.push((first + i))
            }
            return days
        },

        setLabels() {
            const days = this.getWeeksInMonth(2022, this.month)
            switch (this.selectedGroup) {
                case 0:
                    this.labels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
                    break;

                case 1:
                    this.labels = days.map(el => el.end).map(el => {
                        return `Week ${this.currentWeek(el)}`
                    })
                    break;

                case 2:
                    this.labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    break;
            }
        },

        setFilters() {
            switch (this.selectedGroup) {
                case 0:
                        this.filteredRequest = {
                            extras: [['week', this.week],['year', this.year]],
                            group: 'day'
                        }
                    break;
                case 1:
                        this.filteredRequest = {
                            extras: [['month', this.month],['year', this.year]],
                            group: 'week'
                        }
                    break;
                case 2:
                        this.filteredRequest = {
                            extras: [['year', this.year]],
                            group: 'month'
                        }
                    break;
            }

            this.getData()
        },

        

        async getData() {
            const arr = []
            this.values = []
            this.actions.forEach(el => {
                arr.push(this.getAnalytics({action: el.action, extras: this.filteredRequest.extras, group: [this.filteredRequest.group]}))
            })

            const response = await Promise.all(arr)

            const skipped = (ctx, value) => ctx.p0.skip || ctx.p1.skip ? value : undefined;
            const down = (ctx, value) => ctx.p0.parsed.y > ctx.p1.parsed.y ? value : undefined;

            this.actions.forEach((action, i) => {
                this.values.push({
                    data: this.setData(response[i].data.data.data),
                    borderColor: action.color,
                    segment: {
                        borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, action.color),
                        borderDash: ctx => skipped(ctx, [6, 6]),
                    },
                    spanGaps: true,
                })
            })

            this.setChart()
        },

        setData(values) {
            if (this.filteredRequest.group === 'day') {
                const today = new Date().getDate()
                return this.daysOfCurrentWeek().map((el) => {
                    const val = values.find(e => e._id.day === el)
                    if (val) return val.count
                    if (el > today) return NaN
                    return 0
                })
            }


            return this.labels.map((el,i) => {
                const current = this.filteredRequest.group === 'week' ? this.week : this.month
                const comparer = this.filteredRequest.group === 'week' ? el.replace('Week ', '') : i

                const val = values.find(e => e._id[this.filteredRequest.group] == comparer)

                if (val) return val.count
                if (comparer > current) return NaN
                return 0                
            })
        },

        setChart() {
            this.chart?.destroy()

            this.chart = new Chart(this.$refs.mainChart, {
                type: 'line',
                data: {
                    labels: this.labels,
                    datasets: this.values
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                callback: function(label) {
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                },
                            }
                        }
                    },
                    plugins: {
                        legend: false,
                    },
                    fill: false,
                    interaction: {
                        intersect: false
                    },
                    radius: 0,
                }
            });

            this.chart.resize(700, 350);
        }
    }
}
</script>

<style lang="scss" scoped>
.global-chart {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px;

        @media only screen and (max-width: 768px) {
            padding: 0;
        }

        h2 {
            color: black;
            font-weight: 600;
            text-transform: capitalize;
        }

        .data {
            width: 100%;
            display: flex;
            // justify-content: center;
            align-items: center;
            gap: 5rem;

            .info {
                color: black;
                font-size: 16px;
                font-weight: 400;
                width: 30%;
                border: solid 1px black;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                height: 100%;
            }

            .chart-canvas {
                width: 100%;
                max-width: 800px;

                @media only screen and (max-width: 1200px) {
                    min-height: 300px;
                }
            }

            .group {
                display: flex;
                flex-direction: column;

                span {
                    color: black;
                    transition: 0.2s;
                    &.active {
                        color: var(--primary-color);
                    }
                }
            }
        }

        
}
    
</style>