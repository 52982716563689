<template>
    <section>
        <header>
            <h2>{{title}}</h2>
            <aside>
                <template v-for="(filter, i) in filters" :key="i">
                    <span :class="{active: selectedGroup === i}" @click="selectedGroup = i">{{selectedGroup === i ? filter.extent : filter.short}}</span>
                </template>
            </aside>
        </header>
        <div class="container">
            <span>{{value}}</span>
        </div>
    </section>
</template>


<script>
import { mapActions } from "vuex";

export default {
    props: {
        title: {
            type: String,
            default: () => ''
        },
        action: {
            type: String,
            default: () => ''
        },
        refresh: {
            type: Boolean,
            default: () => false
        }
    },

    data() {
        return {
            day: new Date().getDate(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            week: undefined,
            value: 0,
            selectedGroup: 0,
            filteredRequest: {},
            filters: [
                {
                    short: 'D',
                    extent: 'Today'
                },
                {
                    short: 'W',
                    extent: 'Week'
                },
                {
                    short: 'M',
                    extent: 'Month'
                },
                {
                    short: 'Y',
                    extent: 'Year'
                }
            ]
        }
    },

    watch: {
        selectedGroup() {
            this.setFilters()
        },

        refresh(val) {
            if (val) this.getBaseAnalytics()
        },
    },

    created() {
        this.currentWeek()
        this.setFilters()
    },

    methods: {
        ...mapActions({
            getAnalytics: 'analytics/getAnalytics',
        }),

        currentWeek() {
            const currentDate = new Date(this.year, this.month, this.day);
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            this.week = Math.ceil(days / 7)
        },

        setFilters() {
            switch (this.selectedGroup) {
                case 0:
                        this.filteredRequest = {
                            extras: [['day', this.day],['month', this.month],['year', this.year]],
                            group: ['day']
                        }
                    break;
                case 1:
                        this.filteredRequest = {
                            extras: [['week', this.week],['year', this.year]],
                            group: ['week']
                        }
                    break;
                case 2:
                        this.filteredRequest = {
                            extras: [['month', this.month],['year', this.year]],
                            group: ['month']
                        }
                    break;
                case 3:
                        this.filteredRequest = {
                            extras: [['year', this.year]],
                            group: ['year']
                        }
                    break;
            }

            this.getBaseAnalytics()
        },

        async getBaseAnalytics() {
            const { data } = await this.getAnalytics({action: this.action, extras: this.filteredRequest.extras, group: this.filteredRequest.group})
            const value = data.data.data.length ? data.data.data[0].count : 0
            const duration = this.value > value ? 500 / this.value : 500 / value
            
            const counter = setInterval(() => {
                if (this.value > value) {
                    this.value -= 1
                    return
                }

                if (this.value < value) {
                    this.value += 1
                    return
                }

                clearInterval(counter)
            }, duration);
        }
    },
}
</script>

<style lang="scss" scoped>
section {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: rgba(140, 140, 140, 0.1);
    border-radius: 2px;
    // width: 20rem;
    justify-content: space-between;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    position: relative;

    @media only screen and (max-width: 1200px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 4;
        grid-row-end: 5;
    }

    @media only screen and (max-width: 768px) {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 6;
        grid-row-end: 7;
    }

    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        user-select: none;

        h2 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
        }

        aside {
            margin-left: 2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0.2rem;
            position: absolute;
            right: 20px;
            top: 20px;
            span {
                cursor: pointer;
                user-select: none;
                border-radius: 2px;
                padding: 5px;
                transition: 0.2s;

                &.active {
                    background: rgba(0,0,0,0.1);
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }
        }
    }

    .container {
        display: flex;
        span {
            color: var(--primary-color);
            font-size: 40px;
            font-weight: 600;
        }
    }
}
</style>