<template>
    <section>
        <h2>Tickets by Filters</h2>
        <aside class="filters">
            <template v-for="(filter, i) in filters" :key="i">
                <Filter v-if="filter.roles.includes(getUserProfile.role.id)" :style="{width: `${100/filters.length}%`}" :data="filter" :saved-filters="savedFilters" @selectItem="handleSelect" />
            </template>
        </aside>
        <div class="results">
            <div class="info">
                <span>This data refers to this <mark>{{filteredRequest.group}}</mark> tickets</span>
                <template v-if="Object.keys(savedFilters).length">
                    <span>and filtered by selected</span>
                    <template v-for="(filter,i) in Object.keys(savedFilters)" :key="i">
                        <span><mark>{{filter}}</mark></span>
                    </template>
                </template>
            </div>
            <template v-for="(item, i) in actions" :key="i">
                <Bar :item="item" :values="values[i]" :current="currentValues[i]" />
            </template>
        </div>
        
    </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/mixins/optionsApi";
import Filter from "./Filter.vue";
import Bar from "./Bar.vue";
import { GET_USER_PROFILE_GETTER } from '@/store/storeconstants'

export default {
    props: {
        selectedFilter: {
            type: Number,
            default: () => 0
        },
        currentValues: {
            type: Array,
            default: () => []
        },
        refresh: {
            type: Boolean,
            default: () => false
        }
    },

    mixins: [api],

    components: {
        Filter,
        Bar
    },

    data() {
        return {
            filteredRequest: {},
            day: new Date().getDate(),
            month: new Date().getMonth(),
            year: new Date().getFullYear(),
            week: undefined,
            values: [],
            filters: [
                {   
                    name: 'Client',
                    data: this.api("clients"),
                    dependent: false,
                    roles: [4,5,6,7,8]
                },
                {
                    name: 'Site',
                    data: this.api("sites"),
                    dependent: 'client',
                    roles: [4,5,6,7,8,9,10]
                },
                {   
                    name: 'Product',
                    data: this.api("products"),
                    dependent: 'site',
                    roles: [4,5,6,7,8,9,10]
                }
            ],
            savedFilters: {},
            client: localStorage.client ? JSON.parse(localStorage.client) : {},
        }
    },
    
    computed: {
        ...mapGetters('account', {
          getUserProfile: GET_USER_PROFILE_GETTER,
        }),

        actions() {
            return [
                {
                    color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                    name: 'Tickets Created',
                    tag: 'tickets_created',
                    action: 'create-ticket',
                    group: 'is_client'
                },
                {
                    color: '#fb9a4b',
                    name: 'Ticket Calls Made',
                    tag: 'calls_made',
                    action: 'create-ticket-call'
                },
                {
                    color: '#dc3545',
                    name: 'Tickets Closed',
                    tag: 'tickets_closed',
                    action: 'close-ticket'
                }
            ]
        }
    },

    watch: {
        selectedFilter() {
            this.setFilters()
        },

        savedFilters: {
            deep: true,
            handler() {
                this.setFilters()
            }
        },

        refresh: {
            type: String,
            default: () => ''
        },

        getUserProfile: {
            deep: true,
            handler() {
                if (![4,5,6,7,8].includes(this.getUserProfile.role.id)) {
                    this.savedFilters.client = this.client.id
                }
                else {
                    this.setFilters()
                }
            }
        }
    },

    created() {
        this.currentWeek()
        if(this.getUserProfile.id) this.setFilters()
    },

    methods: {
        ...mapActions({
            getAnalytics: 'analytics/getAnalytics',
        }),

        currentWeek() {
            const currentDate = new Date(this.year, this.month, this.day);
            const startDate = new Date(currentDate.getFullYear(), 0, 1);
            const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
            this.week = Math.ceil(days / 7)
        },

        setFilters() {
            switch (this.selectedFilter) {
                case 0:
                        this.filteredRequest = {
                            extras: [['week', this.week],['year', this.year]],
                            group: 'week'
                        }
                    break;
                case 1:
                        this.filteredRequest = {
                            extras: [['month', this.month],['year', this.year]],
                            group: 'month'
                        }
                    break;
                case 2:
                        this.filteredRequest = {
                            extras: [['year', this.year]],
                            group: 'year'
                        }
                    break;
            }

            this.getData()
        },

        async getData() {
            const extraFilters = []

            Object.keys(this.savedFilters).forEach(el => {
                extraFilters.push([el,this.savedFilters[el]])
            })

            const arr = []
            this.actions.forEach(el => {
                arr.push(this.getAnalytics({action: el.action, extras: this.filteredRequest.extras.concat(extraFilters), group: [this.filteredRequest.group, el.group]}))
            })

            const response = await Promise.all(arr)
            
            this.values = []
            response.forEach(el => {
                const data = el.data.data.data
                
                const isMultiple = el.config.url.includes('create-ticket&')
                if (isMultiple) {
                    this.values.push({
                        org: data.find(e => e._id.is_client)?.count || 0,
                        client: data.find(e => !e._id.is_client)?.count || 0
                    })
                    return
                }

                this.values.push(data.length ? data.reduce(e => e.count).count : 0)
            })
            
        },

        handleSelect(val) {
            this.savedFilters[val[0]] = val[1]

            if (!val[1]) {
                delete this.savedFilters[val[0]]
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    section {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 2;
        grid-row-end: 5;
        background: rgba(140, 140, 140, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1200px) {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 3;
        }

        @media only screen and (max-width: 768px) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 6;
        }

        h2 {
            font-weight: 600;
            font-size: 24px;
            margin: 0;
        }

        .filters {
            display: flex;
            gap: 1rem;
            margin-top: 1rem;
        }

        .results {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: 100%;
            width: 100%;
            margin-top: 1rem;
            
            .info {
                display: flex;
                gap: 0.2rem;
            }
        }
    }

    mark {
        cursor: unset;
    }
</style>