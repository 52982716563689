<template>
    <div class="analytics">
        <h3>Analytics</h3>
        <div class="grid">
            <template v-for="(item, i) in basics" :key="i">
                <Counter v-if="item.type === 'counter'" :title="item.title" :action="item.action" :refresh="refresh" />
                <Licences v-else-if="item.type === 'licences'" :title="item.title" :refresh="refresh" />
                <Storage v-else-if="item.type === 'storage'" :title="item.title" :refresh="refresh" />
                <Multiple v-else-if="item.type === 'multiple'" :title="item.title" :actions="item.actions" @newValues="handleNewValues" @changeView="handleNewView" :refresh="refresh" />
            </template>
            <Details :selected-filter="selectedFilter" :current-values="currentValues" />
        </div>
    </div>
</template>

<script>
import Counter from "@/components/Ui/Analytics/Counter.vue";
import Multiple from "@/components/Ui/Analytics/Multiple.vue";
import Storage from "@/components/Ui/Analytics/Storage.vue";
import Licences from "@/components/Ui/Analytics/Licences.vue";
import Details from "@/components/Ui/Analytics/Graph/Details.vue";

export default {
    components: {
        Counter,
        Multiple,
        Licences,
        Storage,
        Details,
        // Graph
    },

    data() {
        return {
            basics: [
                {
                    type: 'multiple',
                    title: 'Tickets',
                    actions: [
                        {
                            color: getComputedStyle(document.documentElement).getPropertyValue('--primary-color'),
                            name: 'Tickets Created',
                            action: 'create-ticket'
                        },
                        {
                            color: '#fb9a4b',
                            name: 'Ticket Calls Made',
                            action: 'create-ticket-call'
                        },
                        {
                            color: '#dc3545',
                            name: 'Tickets Closed',
                            action: 'close-ticket'
                        }
                    ],
                },
                {
                    type: 'counter',
                    title: 'Logins',
                    action: 'login',
                },
                {
                    type: 'licences',
                    title: 'Licences',
                    action: 'create-ticket-call',
                },
                {
                    type: 'storage',
                    title: 'Storage'
                },
                
            ],
            selectedFilter: 0,
            currentValues: [],
            interval: undefined,
            refresh: false,
        }
    },

    mounted() {
        this.interval = setInterval(() => {
            this.refresh = true
            setTimeout(() => {
                this.refresh = false
            }, 100);
        }, 300000);
    },

    beforeUnmount() {
        clearInterval(this.interval)
    },

    methods: {
        handleNewView(value) {
            this.selectedFilter = value
        },

        handleNewValues(values) {
            this.currentValues = values
        }
    }
}
</script>

<style lang="scss" scoped>
    .analytics {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 768px) {
            margin-bottom: 50px;
        }

        .grid {
            width: 100%;
            display: grid;
            grid-template-columns: 46% 17% 17% 17%;
            grid-template-rows: 150px 150px 150px; 
            gap: 1rem;

            @media only screen and (max-width: 1200px) {
                grid-template-columns: 50% 50%;
                grid-template-rows: 150px 150px 150px 150px; 
            }

            @media only screen and (max-width: 768px) {
                grid-template-columns: 100%;
                grid-template-rows: 150px 150px 150px 150px 150px 150px; 
            }

            @media only screen and (max-width: 550px) {
                grid-template-columns: 100%;
                grid-template-rows: 150px 50px 150px 150px 150px 150px; 
            }
        }

        .basic {
            display: flex;
            gap: 1rem;
        }

        .graph {
            width: 100%;
            display: flex;
            gap: 1rem;
            align-items: flex-end;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 24px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            padding: 0;

            span {
                width: 62rem;
                background: rgba(140, 140, 140, 0.1);
                height: calc(392px - 1rem);
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
</style>